import{Message} from  'element-ui'
import { encryptCode,decryptCode } from "@/utils/function";
import { request_sign } from '@/utils/const';
import API from '@/api/teacher/calendar/ExamsList'
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
  name: "StudentsList",
  components: {},
  inject:['reload'],
  data() {
    return {
      examList:[],
      project_id:0,
      student_id:0,
      eventStr:'',
      dataList:[],
      dialogVisible: false
    };
  },
  created() {
    let self = this;
    // 学生id
    self.student_id = self.$route.query.ids;
    // 项目id
    self.project_id = self.$route.query.project_id;
    //  获取学生考试列表数据
    this.getScoreList();
  },
  methods: 
  {
    handleOK()
    {
      let self = this;
        let str = encryptCode({
          student_id : self.student_id,
          project_id : self.project_id
        })
        let data = { sign: request_sign, params: str };
        nprogressStart();
        self.$Loading.show();
        API.restoreExam(data)
          .then((res)=>{
            nprogressClose();
            self.$Loading.hide();
            if(res.code > 0 )
            {
              self.$Tips({
                // 消息提示内容
                message:res.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType:'success',
                displayTime:1500
              })
              self.dialogVisible = false;
            }
          })
    },

    resetScore()
    {
      let self = this;
      self.dialogVisible = true;
    },

    getScoreList() {
      try 
      {
        let self = this;
        let str = encryptCode({
          student_id : self.student_id,
          project_id : self.project_id
        })
        let data = { sign: request_sign, params: str };
        nprogressStart();
        self.$Loading.show();
        API.getScoreList(data)
          .then((res)=>{
            nprogressClose();
            self.$Loading.hide();
            if(res.code > 0 )
            {
              self.eventStr = res.eventStr;
              self.dataList = res.data;
            }
          })
      } 
      catch (error) 
      {
        self.$Tips({
          // 消息提示内容
          message:'系统错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })
      }
    },
    
  },
};















