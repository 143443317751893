import { request } from "@/request/http";

export default {
    getScoreList: (data) => {
        return request("POST", `/index.php/backcalendar/getScoreList`,data);
    },

    restoreExam: (data) => {
        return request("POST", `/index.php/backcalendar/restoreExam`,data);
    },
    
};